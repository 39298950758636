import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardDetail = ({ className }) => {
  return (
    /* card detail block html */
    <div className={`${className} card-detail`}>
      <div className="image-holder">
        <img src="/assets/images/img-card.jpg" alt="image description" />
      </div>
      <div className="text-holder">
        <div className="user-detail">
          <div className="user-name">
            <div className="img">
              <img src="/assets/images/user.png" alt="user" />
            </div>
            <strong className="name">@mariarosser</strong>
          </div>
          <ul className="social-info">
            <li>
              <span className="title">Instagram</span>
              <strong className="val">20.3M (+5.3%)</strong>
            </li>
            <li>
              <span className="title">TikTok</span>
              <strong className="val">13.5M</strong>
            </li>
            <li>
              <span className="title">Twitter</span>
              <strong className="val">2.3M</strong>
            </li>
          </ul>
        </div>
        <Link to={"#"} className="btn btn-solid">
          Price: Ξ0.3($854.46)
        </Link>
      </div>
    </div>
  );
};

/* card detail block styles */
export default styled(CardDetail)`
  position: relative;
  overflow: hidden;

  .image-holder {
    border: 2px solid #000000;

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .text-holder {
    padding: 25px 0;
    text-align: center;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .user-detail {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 54px;

    @media (max-width: 1023px) {
      margin: 0 0 10px;
    }
  }

  .user-name {
    display: flex;
    align-items: center;
    padding: 0 15px 0 0;
    font-size: 24px;
    margin: 0 0 20px;

    @media (max-width: 1199px) {
      font-size: 18px;
    }

    @media (max-width: 1023px) {
      width: 100%;
      padding: 0;
      font-size: 24px;
    }

    .img {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      padding: 0 0 0 15px;
    }
  }

  .social-info {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    @media (max-width: 1023px) {
      width: 100%;
    }

    li {
      display: flex;
      margin: 0 0 8px;

      @media (max-width: 1023px) {
        justify-content: space-between;
      }

      @media (max-width: 767px) {
        margin: 0 0 13px;
      }
    }

    .title {
      min-width: 100px;
      color: #c4c4c4;
      text-align: left;
      font-size: 14px;

      @media (max-width: 1199px) {
        min-width: 80px;
      }
    }

    .val {
      text-align: right;
      display: block;
      min-width: 140px;
    }
  }

  .btn {
    display: block;
  }
`;
