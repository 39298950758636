import React from "react";
import styled from "styled-components";

const InnerVisual = ({ className }) => {
  return (
    /* explore page banner html */
    <div className={`${className} inner-visual-block`}>
      <img src="/assets/images/inner-banner.jpg" alt="banner image" />
    </div>
  );
};

/* explore page banner style */
export default styled(InnerVisual)`
  position: relative;

  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;
