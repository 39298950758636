import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Header, Visual, VisualSoon, SoonBlock, SimpleCard, Footer } from "components";

const Soon = ({ className }) => {
  return (
    /* soon page html */
    <div className={className}>
      <Header />
      <VisualSoon />
    </div>
  );
};

/* soon page styles */
export default styled(Soon)`
  /* featured block styles */
  .arImage {
    width: 24px;
    height: 24px;
    margin-left: 0.5rem;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234a4a4a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'%3E%3C/path%3E%3C/svg%3E");;
  }
  .block-featured {
    position: relative;
    overflow: hidden;
    padding: 52px 0 100px;

    @media (max-width: 1023px) {
      padding: 42px 0 100px;
    }

    h2 {
      margin: 0 0 57px;

      @media (max-width: 767px) {
        margin: 0 0 35px;
      }
    }

    .row {
      margin: 0 -40px 154px;

      @media (max-width: 1199px) {
        margin: 0 -40px 53px;
      }

      @media (max-width: 1023px) {
        margin: 0 -20px 53px;
      }
    }

    .col {
      padding: 0 40px;
      width: 50%;
      margin: 0 0 20px;

      @media (max-width: 1023px) {
        padding: 0 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 29px;
      }
    }
  }
`;
