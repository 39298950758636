import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const BannerDetail = ({ className }) => {
  return (
    /* banner detail block html*/
    <div
      className={`${className} bannerDetail-block`}
      style={{ backgroundImage: "url('/assets/images/bg-block.png')" }}
    >
      <div className="user-detail-area">
        <div className="container">
          <div className="user-name">
            <div className="img">
              <img src="/assets/images/user.png" alt="user" />
            </div>
            <strong className="name">@mariarosser</strong>
          </div>
          <Link to={"#"} className="btn btn-solid">
            Price: Ξ0.3($854.46)
          </Link>
        </div>
      </div>
    </div>
  );
};

/* banner detail block styles*/
export default styled(BannerDetail)`
  position: relative;
  background-color: #e99fc6;
  min-height: 680px;
  display: flex;
  align-items: flex-end;
  background-size: 1440px auto;
  background-position: 50% 0;

  @media (max-width: 767px) {
    min-height: 587px;
  }

  .user-detail-area {
    width: 100%;
    padding: 0 0 24px;

    @media (max-width: 767px) {
      padding: 0 0 4px;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
  }

  .user-name {
    display: flex;
    align-items: center;
    margin: 0 0 15px;

    .img {
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      padding: 0 0 0 15px;
      font-size: 24px;
    }
  }

  .btn {
    min-width: 361px;
    margin: 0 0 15px;

    @media (max-width: 767px) {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 100%;
      margin: 16px 0 0;
      min-width: inherit;
    }
  }
`;
