import React from "react";
import styled from "styled-components";

const Error = ({ className }) => {
  return (
    /* 404 page html */
    <div className={className}>
      <div className="page-content">
        <div className="container">
          <strong className="error-code">
            4<span className="center">0</span>4
          </strong>
          <p>Page not found</p>
        </div>
      </div>
    </div>
  );
};

/* 404 page style */
export default styled(Error)`
  min-height: 100vh;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  line-height: 1.3;

  .page-content {
    width: 100%;
  }

  .error-code {
    font-size: 100px;
    line-height: 1.2;
    margin: 0 0 5px;
  }

  .center {
    color: #e99fc6;
  }
`;
