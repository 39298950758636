import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Visual = ({ className }) => {
  return (
    /* visual block html */
    <div className={`${className} visual-block`}>
      <div
        className="visual"
        style={{ backgroundImage: "url('/assets/images/inner-banner.jpg')" }}
      >
        <div className="container">
          <h1>
            AR NFTS
            <br /> OF INFLUENCERS
          </h1>
          <Link to={"#"} className="visual__link">
            Shop Now <i className="icon fas fa-long-arrow-alt-right"></i>
          </Link>
          <div className="visual__image">
          </div>
        </div>
      </div>
    </div>
  );
};

/* text block styles */
export default styled(Visual)`
  .visual {
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 680px;
      padding-top: 180px;
      padding-bottom: 130px;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    h1 {
      color: #fff;
      margin: 0;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        margin: 0 0 10px;
      }

      @media (max-width: 374px) {
        font-size: 34px;
      }
    }

    &__image {
      overflow: hidden;
      text-align: center;
      width: 424px;
      position: absolute;
      left: 50%;
      top: 40px;
      margin-left: -13px;
      transform: translateX(-50%);
    }

    &__link {
      font-size: 16px;
      line-height: 1.375;
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      z-index: 1;

      .icon {
        margin-left: 10px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__list {
      font-size: 24px;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 700;
      position: absolute;
      left: 50%;
      bottom: 70px;
      transform: translateX(-50%);
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      width: 1340px;
      letter-spacing: 3px;

      @media (max-width: 767px) {
        width: 1270px;
        bottom: 40px;
      }

      li {
        padding: 0 24px;

        @media (max-width: 767px) {
          padding: 0 10px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          display: block;
          color: #fff;
          opacity: 0.4;

          &:hover {
            opacity: 0.7;
          }
        }

        &.active {
          a {
            opacity: 1;
          }
        }
      }
    }
  }
`;
