import React from "react";
import styled from "styled-components";
import { Header, CardDetail, InnerVisual, Footer } from "components";

const Explore = ({ className }) => {
  return (
    /* explore  page html */
    <div className={className}>
      <Header />
      <InnerVisual />
      <section className="explore-cards">
        <div className="container">
          <h2 className="h1">Other Influencers</h2>
          <div className="row">
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

/* explore page styles */
export default styled(Explore)`
  .explore-cards {
    padding: 127px 0 197px;

    @media (max-width: 1199px) {
      padding: 80px 0;
    }

    h2 {
      margin: 0 0 103px;
      text-align: center;

      @media (max-width: 1023px) {
        margin: 0 0 49px;
      }

      @media (max-width: 767px) {
        font-size: 32px;
      }
    }

    .row {
      margin: 0 -40px;

      @media (max-width: 1023px) {
        margin: 0 -20px;
      }
    }

    .col {
      padding: 0 40px;
      width: 50%;
      margin: 0 0 56px;

      @media (max-width: 1023px) {
        padding: 0 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 19px;
      }
    }
  }
`;
