import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardProduct = ({ className }) => {
  return (
    /* product detail block card html */
    <div className={`${className} product-card`}>
      <div className="image-holder">
        <video className="img" autoPlay muted loop width='436'>
          <source src="/assets/videos/beanie.mp4" type="video/mp4"/>
        </video>
      </div>
      <div className="text-holder">
        <Link to={"#"} className="btn btn-solid">
          Price: Ξ0.3($854.46)
        </Link>
      </div>
    </div>
  );
};

/* product detail block card styles */
export default styled(CardProduct)`
  .image-holder {
    position: relative;
    margin: 0 0 25px;
    // border: 2px solid #000;

    @media (max-width: 767px) {
      order: 1;
      margin: 0 0 18px;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .text-holder {
    width: 436px;
    @media (max-width: 767px) {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 130px;
    }
  }

  .btn {
    display: block;
  }
`;
