import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SimpleCard = ({ className }) => {
  return (
    /* simple card html */
    <div className={`${className} card-block`}>
      <div className="image-holder">
        <img src="/assets/images/img-card.jpg" alt="image description" />
      </div>
      <div className="text-holder">
        <strong className="name">
          <Link to={"#"}>@mariarosser</Link>
        </strong>
      </div>
    </div>
  );
};

/* simple card styles */
export default styled(SimpleCard)`
  position: relative;
  overflow: hidden;

  .image-holder {
    border: 2px solid #000000;

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .text-holder {
    padding: 32px 0;
    text-align: center;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 1px;

    @media (max-width: 767px) {
      padding: 23px 0;
    }
  }
`;
