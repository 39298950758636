import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Header = ({ className }) => {
  return (
    /* header block html */
    <header className={`${className} header`}>
      <div className="container">
        <stong className="logo">
          <Link to={"#"}>
            <img src="/assets/images/logo.svg" alt="Idolate" />
          </Link>
        </stong>
        <div className="navigation">
          <span
            className="navigation__opener"
            onClick={() => {
              document.body.classList.toggle("nav-active");
            }}
          ></span>
          <div className="navigation__drop">
            <stong className="logo">
              <Link to={"#"}>
                <img src="/assets/images/logo.svg" alt="Idolate" />
              </Link>
            </stong>
            <ul className="navigation__menu">
              <li>
                <Link to={"#"}>
                  <span className="navigation__text">Explore</span>
                  <span className="navigation__icon">
                    <img src="/assets/images/eye.svg" alt="eye" />
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <span className="navigation__text">About</span>
                  <span className="navigation__icon">
                    <img src="/assets/images/flower.svg" alt="flower" />
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <span className="navigation__text">Contact</span>
                  <span className="navigation__icon">
                    <img src="/assets/images/rainbow.svg" alt="eye" />
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <span className="navigation__text">Press</span>
                  <span className="navigation__icon">
                    <img src="/assets/images/cherry.svg" alt="eye" />
                  </span>
                </Link>
              </li>
            </ul>
            <ul className="social-networks">
              <li>
                <Link to={"#"}>
                  <i className="fab fa-instagram"></i>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <i className="fab fa-tiktok"></i>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

/* header block styles */
export default styled(Header)`
  &.header {
    width: 100%;
    padding: 30px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    @media (max-width: 767px) {
      padding: 24px 0;
    }
  }

  .logo {
    width: 162px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1023px) {
      width: 130px;
    }

    @media (max-width: 767px) {
      width: 100px;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .navigation {
    width: 100%;

    .logo {
      position: static;
      transform: none;
      margin: 13px 17px;
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &__opener {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 16px;
      top: 12px;
      background-image: url("/assets/images/hamburger.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: left 0.35s ease-in-out;
      z-index: 1;
      display: none;

      @media (max-width: 767px) {
        display: block;
      }

      .nav-active & {
        @media (max-width: 767px) {
          left: 244px;
          background-image: url("/assets/images/hamburger-open.svg");
        }
      }
    }

    &__drop {
      @media (max-width: 767px) {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 284px;
        overflow: auto;
        background: rgba(0, 0, 0, 0.9);
        transition: transform 0.35s ease-in-out;
        transform: translateX(-100%);
      }

      .nav-active & {
        @media (max-width: 767px) {
          transform: none;
        }
      }
    }

    &__menu {
      font-size: 24px;
      line-height: 1.2;
      margin: 0;
      list-style: none;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 23px;

      @media (max-width: 767px) {
        display: block;
        min-height: 350px;
        padding: 20px 17px 80px;
      }

      li {
        padding: 0 15px;

        @media (max-width: 767px) {
          padding: 8px 0;
        }

        &:nth-child(2) {
          padding-right: 16.7%;

          @media (max-width: 1023px) {
            padding-right: 20%;
          }

          @media (max-width: 767px) {
            padding-right: 0;
          }
        }

        &:nth-child(3) {
          padding-left: 16.7%;

          @media (max-width: 1023px) {
            padding-left: 20%;
          }

          @media (max-width: 767px) {
            padding-left: 0;
          }
        }

        a {
          color: #fff;
          display: flex;
          align-items: center;
          position: relative;
          min-height: 40px;

          &.active {
            &:before {
              opacity: 1;
              visibility: visible;
            }
          }

          &:before {
            position: absolute;
            left: 0;
            right: 0;
            top: -30px;
            height: 5px;
            background: #b7c8f0;
            content: "";
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease;

            @media (max-width: 767px) {
              display: none;
            }
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    &__text {
      display: none;

      @media (max-width: 767px) {
        display: block;
        margin: 0 10px 0 0;
      }
    }

    &__icon {
      display: block;

      img {
        display: block;

        @media (max-width: 1023px) {
          transform: scale(0.8);
        }

        @media (max-width: 767px) {
          transform: scale(0.6);
        }
      }
    }

    .social-networks {
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
      display: none;

      @media (max-width: 767px) {
        display: flex;
      }
    }
  }

  .social-networks {
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 10px;

      a {
        color: #fff;
        display: block;
      }
    }
  }
`;
