import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SoonBlock = ({ className }) => {
  return (
    /* text block html */
    <div className={`${className} text-block`}>
      <div className="container">
        <div className="text-wrap">
          <p>
            Collect, own, and participate in the influencer economy.
            Building a path towards creator/follower owned enterprise 
            through an exclusive NFT library and tokenized communities and events.
          </p>
        </div>
      </div>
    </div>
  );
};

/* text block styles*/
export default styled(SoonBlock)`
  padding: 150px 80px 30px;
  font-size: 34px;
  line-height: 1.375;
  font-weight: 600;
  position:absolute;
  top: 400px;
  @media (max-width: 1023px) {
    padding: 79px 0 30px;
  }

  .text-wrap {
    max-width: 750px;
  }

  h2 {
    margin: 0 0 30px;
    line-height: 1.4;

    @media (max-width: 1023px) {
      line-height: 1.2;
      margin: 0 0 33px;
    }
  }

  p {
    margin: 0 0 33px;
  }
`;
