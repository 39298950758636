import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const mailchimp = require('@mailchimp/mailchimp_marketing');


const VisualSoon = ({ className }) => {
  const [email, setEmail] = useState('');

  let handleSubmit = async e => {
    e.preventDefault();
    console.log({email});
    const response = await fetch('/mailchimp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    const body = await response.text();
    setEmail('');
    console.log(body);

  }

  const handleEmailInputChange = (event) => {
    event.persist();
    setEmail(event.target.value);
    console.log({email});
  }
  return (
    /* visual block html */
    <div className={`${className} visual-block`}>
      <div
        className="visual"
        style={{ backgroundImage: "url('/assets/images/img-visual.png')" }}
      >
      </div>
      <div className="container">
        <h1>
          COMING SOON
        </h1>
      </div>
      <div className="container-email">
        <h1>
          You're invited
        </h1>
        <form className="form-subscription" onSubmit={handleSubmit}>
          <input id="emailForm" type="email" value = {email} 
          placeholder="Email" onChange={handleEmailInputChange} />
        </form>
      </div>
      <div className="blockContainer">
        <div className="text-wrap">
          <p>
            Collect, own, and participate in the influencer economy.
            Building a path towards creator/follower owned enterprise 
            through an exclusive NFT library and tokenized communities and events.
          </p>
        </div>
      </div>
    </div>
  );
};



/* text block styles */
export default styled(VisualSoon)`
    .blockContainer {
      padding: 150px 80px 30px;
      font-size: 34px;
      line-height: 1.375;
      font-weight: 600;
      position:absolute;
      top: 400px;
      @media (max-width: 1023px) {
        padding: 40px 20px 10px;
        bottom: 0;
      }

      .text-wrap {
        max-width: 750px;
      }

      h2 {
        margin: 0 0 30px;
        line-height: 1.4;

        @media (max-width: 1023px) {
          line-height: 1.2;
          margin: 0 0 33px;
        }
      }

      p {
        margin: 0 0 33px;
      }
    }
    .container {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      min-height: 680px;
      top:0px;
      padding-top: 10px;
      padding-bottom: 130px;

      @media (max-width: 1023px) {
        min-height: 380px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      h1 {
        font-size: 48px;
        padding-left: 80px;
      }
    }
    .container-email {
      position: absolute;
      right: 100px;
      top: 400px;
      @media (max-width: 1023px) {
        top: 200px;
      }
      #emailForm {
        margin-top: 25px;
        width: 300px;
        
        font: 24px;
        border: 1px solid;
      }
    }

    h1 {
      color: #000;
      margin: 0;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        margin: 0 0 10px;
      }

      @media (max-width: 374px) {
        font-size: 34px;
      }
    }

  .visual {
    overflow: hidden;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 1000px;
    margin-top: 100px;

    &__image {
      overflow: hidden;
      text-align: center;
      width: 424px;
      position: absolute;
      left: 50%;
      top: 40px;
      margin-left: -13px;
      transform: translateX(-50%);
    }

    &__link {
      font-size: 16px;
      line-height: 1.375;
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      z-index: 1;

      .icon {
        margin-left: 10px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__list {
      font-size: 24px;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 700;
      position: absolute;
      left: 50%;
      bottom: 70px;
      transform: translateX(-50%);
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      width: 1340px;
      letter-spacing: 3px;

      @media (max-width: 767px) {
        width: 1270px;
        bottom: 40px;
      }

      li {
        padding: 0 24px;

        @media (max-width: 767px) {
          padding: 0 10px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          display: block;
          color: #fff;
          opacity: 0.4;

          &:hover {
            opacity: 0.7;
          }
        }

        &.active {
          a {
            opacity: 1;
          }
        }
      }
    }
  }
`;
