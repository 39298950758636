import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Header, Visual, TextBlock, SimpleCard, Footer } from "components";

const Home = ({ className }) => {
  return (
    /* home page html */
    <div className={className}>
      <Header />
      <Visual />
      <TextBlock />
      {/* featured block html */}
      <section className="block-featured">
        <div className="container">
          <h2 className="h1">Featured</h2>
          <div className="text-center">
            <Link to={"#"} className="link-text">
              Shop all <i class="fas fa-long-arrow-alt-right"></i>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

/* home page styles */
export default styled(Home)`
  /* featured block styles */
  .block-featured {
    position: relative;
    overflow: hidden;
    padding: 52px 0 100px;

    @media (max-width: 1023px) {
      padding: 42px 0 100px;
    }

    h2 {
      margin: 0 0 57px;

      @media (max-width: 767px) {
        margin: 0 0 35px;
      }
    }

    .row {
      margin: 0 -40px 154px;

      @media (max-width: 1199px) {
        margin: 0 -40px 53px;
      }

      @media (max-width: 1023px) {
        margin: 0 -20px 53px;
      }
    }

    .col {
      padding: 0 40px;
      width: 50%;
      margin: 0 0 20px;

      @media (max-width: 1023px) {
        padding: 0 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 29px;
      }
    }
  }
`;
