import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Home, Product, Soon, Error, Explore } from "pages";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact element={<Home />} path="/home" />
        <Route exact element={<Soon />} path="/" />
        <Route exact element={<Product />} path="/product-detail" />
        <Route exact element={<Explore />} path="/explore" />
        <Route element={<Error />} path="*" />
      </Routes>
    </div>
  );
}

export default App;
