import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Description = ({ className }) => {
  return (
    /* product detail block card desription */
    <div className={`${className} description-block`}>
      <div className="text-area">
        <h1>Crazy Life is Nuts X Coca Strawberry Club </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
        </p>
      </div>
      <div className="user-name">
        <div className="img">
          <img src="/assets/images/user.png" alt="user" />
        </div>
        <strong className="name">@mariarosser</strong>
      </div>
      <div className="social-info">
        <ul className="followers-info">
          <li>
            <span className="hidden-mobile">
              <i className="fab fa-instagram"></i>
            </span>
            <span className="visible-mobile title">Instagram</span>
            <span className="text">20.3M(+5.3%)</span>
          </li>
          <li>
            <span className="hidden-mobile">
              <i className="fab fa-tiktok"></i>
            </span>
            <span className="visible-mobile title">TikTok</span>
            <span className="text">13.5M</span>
          </li>
          <li>
            <span className="hidden-mobile">
              <i className="fab fa-twitter"></i>
            </span>
            <span className="visible-mobile title">Twitter</span>
            <span className="text">2.3M</span>
          </li>
        </ul>
        <ul className="extra-info">
          <li>
            <span className="title">Dropped</span>
            <span className="text">9/21/21 7:00PM PST</span>
          </li>
          <li>
            <span className="title">Total Supply</span>
            <span className="text">84</span>
          </li>
          <li>
            <span className="title">Prints Owned</span>
            <span className="text">84</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

/* product detail block card styles */
export default styled(Description)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;

  .text-area {
    max-width: 485px;
  }

  h1 {
    margin: 0 0 15px;
    line-height: 1.4;
    font-weight: 800;

    @media (max-width: 1023px) {
      line-height: 1.38;
      margin: 0 0 21px;
    }
  }

  p {
    margin: 0 0 41px;

    @media (max-width: 767px) {
      margin: 0 0 24px;
    }
  }

  .user-name {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    .img {
      width: 56px;
      height: 56px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      padding: 0 0 0 15px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .social-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
  }

  .followers-info {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 1023px) {
      width: 100%;
    }

    li {
      margin: 0 0 8px;
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        justify-content: space-between;
      }
    }

    .title {
      color: #c4c4c4;
      padding: 0 10px 0 0;
    }

    .fab {
      color: #c4c4c4;
      font-size: 20px;
      line-height: 1;
      min-width: 20px;
      margin: 0 15px 0 0;
    }

    .text {
      font-family: "Overpass Mono", monospace;
    }
  }

  .extra-info {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 1023px) {
      width: 100%;
    }

    li {
      margin: 0 0 6px;
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        justify-content: space-between;
      }
    }

    .title {
      color: #c4c4c4;
      min-width: 150px;
      padding: 0 10px 0 0;

      @media (max-width: 1199px) {
        min-width: 120px;
        padding: 0 5px 0 0;
      }

      @media (max-width: 1023px) {
        min-width: inherit;
      }
    }

    .text {
      font-family: "Overpass Mono", monospace;
    }
  }
`;
