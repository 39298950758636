import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Header,
  CardDetail,
  BannerDetail,
  ProductDescription,
  ProductCard,
  Footer,
} from "components";

const Product = ({ className }) => {
  return (
    /* product page html */
    <div className={className}>
      <Header />
      {/* product detail block html */}
      <section className="product-detail">
        <div className="container">
          <div className="product-wrap">
            <ProductDescription />
            <ProductCard />
          </div>
          <Link to={"#"} className="btn-back">
            Back
          </Link>
        </div>
      </section>
      <BannerDetail />
      <section className="cards-area">
        <div className="container">
          <h2 className="h1">Other Influencers</h2>
          <div className="row">
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
            <div className="col">
              <CardDetail />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

/* product page styles */
export default styled(Product)`
  /* product detail block styles */
  .product-detail {
    padding: 163px 0 120px;
    position: relative;

    @media (max-width: 1023px) {
      padding: 120px 0;
    }

    @media (max-width: 767px) {
      padding: 69px 0 213px;
    }

    .container {
      position: relative;

      @media (max-width: 767px) {
        position: static;
      }
    }

    .product-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .description-block {
      width: 48%;
      padding: 0 5% 30px 0;

      @media (max-width: 1199px) {
        padding: 0 0 30px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 0;
        order: 2;
      }
    }

    .product-card {
      width: 46.9%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .btn-back {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      position: absolute;
      left: 20px;
      bottom: 0;

      @media (max-width: 767px) {
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, 0);
        bottom: 66px;
      }
    }
  }

  .cards-area {
    padding: 127px 0 197px;

    @media (max-width: 1199px) {
      padding: 146px 0 158px;
    }

    h2 {
      margin: 0 0 103px;
      text-align: center;

      @media (max-width: 1023px) {
        margin: 0 0 49px;
      }

      @media (max-width: 767px) {
        font-size: 32px;
      }
    }

    .row {
      margin: 0 -40px;

      @media (max-width: 1023px) {
        margin: 0 -20px;
      }
    }

    .col {
      padding: 0 40px;
      width: 50%;
      margin: 0 0 56px;

      @media (max-width: 1023px) {
        padding: 0 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 19px;
      }
    }
  }
`;
