import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TextBlock = ({ className }) => {
  return (
    /* text block html */
    <div className={`${className} text-block`}>
      <div className="container">
        <div className="text-wrap">
          <h2 className="h1">
            Idolate is a collection of twelve exclusive contemporary influencer
            art
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
            odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
            Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
            pede.
          </p>
          <Link to={"#"} className="link-text">
            Learn MORE <i class="fas fa-long-arrow-alt-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

/* text block styles*/
export default styled(TextBlock)`
  padding: 100px 0 30px;
  font-size: 24px;
  line-height: 1.375;
  font-weight: 600;

  @media (max-width: 1023px) {
    padding: 79px 0 30px;
  }

  .text-wrap {
    max-width: 896px;
  }

  h2 {
    margin: 0 0 30px;
    line-height: 1.4;

    @media (max-width: 1023px) {
      line-height: 1.2;
      margin: 0 0 33px;
    }
  }

  p {
    margin: 0 0 33px;
  }
`;
