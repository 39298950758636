import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = ({ className }) => {
  return (
    /* footer block html */
    <div className={`${className} footer`}>
      <div className="container">
        <div className="top-area">
          <div className="logo-area">
            <strong className="logo">
              <Link to={"#"}>
                <img
                  src="/assets/images/logo-white.svg"
                  alt="image description"
                />
              </Link>
            </strong>
            <form action="#" className="form-subscription">
              <p>Join our newsletter to stay up to date on releases</p>
              <input type="email" placeholder="Enter your email address" />
            </form>
          </div>
          <div className="links-area">
            <ul className="footer-links">
              <li>
                <Link to={"#"}>About</Link>
              </li>
              <li>
                <Link to={"#"}>Contact</Link>
              </li>
              <li>
                <Link to={"#"}>How It works</Link>
              </li>
              <li>
                <Link to={"#"}>FAQs</Link>
              </li>
              <li>
                <Link to={"#"}>Crypto NFTs</Link>
              </li>
            </ul>
          </div>
          <ul class="social-networks">
            <li>
              <Link to={"#"}>
                <i class="fab fa-instagram" aria-hidden="true"></i>Instagram
              </Link>
            </li>
            <li>
              <Link to={"#"}>
                <i class="fab fa-tiktok" aria-hidden="true"></i>TikTok
              </Link>
            </li>
            <li>
              <Link to={"#"}>
                <i class="fab fa-twitter" aria-hidden="true"></i>Twitter
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom-area">
          <p>
            &copy; 2021 <Link to={"#"}>idolate</Link>. All rights reserved.
          </p>
          <ul className="bottom-links">
            <li>
              <Link to={"#"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"#"}>Terms of Service</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

/* card detail block style */
export default styled(Footer)`
  &.footer {
    font-size: 16px;
    line-height: 1.375;
    background: #000;
    color: rgba(255, 255, 255, 0.4);
    padding: 40px 0 10px;

    @media (max-width: 767px) {
      padding: 36px 0 10px;
    }

    a {
      color: rgba(255, 255, 255, 0.4);

      &:hover {
        color: #fff;
      }
    }

    .container {
      max-width: 1238px;
    }
  }

  .top-area {
    display: flex;
    justify-content: space-between;
    padding: 0 0 38px;

    @media (max-width: 1199px) {
      padding: 0 0 25px;
    }

    @media (max-width: 1023px) {
      padding: 0 0 15px;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .logo-area {
    width: 345px;

    @media (max-width: 1023px) {
      width: 300px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px;
    }
  }

  .logo {
    display: block;
    width: 124px;
    margin: 0 0 64px;

    @media (max-width: 767px) {
      margin: 0 0 15px;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .links-area {
    width: 316px;
    margin: 7px 66px 0 0;

    @media (max-width: 1199px) {
      margin: 7px 20px 0 0;
    }

    @media (max-width: 1023px) {
      width: 250px;
      margin: 7px 10px 0 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 23px;
    }
  }

  .social-networks {
    margin: 7px 0 0;
    padding: 0;
    list-style: none;
    width: 114px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }

    li {
      margin: 0 0 10px;

      @media (max-width: 767px) {
        margin: 0 0 12px;
      }
    }

    a {
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }

    .fab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0;

      @media (max-width: 767px) {
        margin: 0 14px 0 0;
      }
    }
  }

  .form-subscription {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin: 0 0 20px;

    p {
      margin: 0 0 12px;

      @media (max-width: 767px) {
        max-width: 70%;
        margin: 0 0 32px;
      }
    }

    input[type="text"],
    input[type="email"] {
      font-size: 16px;
      line-height: 20px;
      max-width: 285px;
      width: 100%;
      background: none;
      outline: none;
      box-shadow: none;
      border: 1px solid #808080;
      padding: 13px 15px;
      color: #fff;

      @media (max-width: 767px) {
        max-width: 480px;
      }

      &::placeholder {
        color: #393939;
      }
    }
  }

  .footer-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 0 15px;
      width: 50%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 46%;
        margin: 0 0 17px;
      }
    }

    a {
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .bottom-area {
    padding: 50px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 1023px) {
      padding: 30px 0;
    }

    @media (max-width: 767px) {
      display: block;
      padding: 50px 0;
    }

    p {
      margin: 0 0 17px;
    }
  }

  .bottom-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    li {
      padding: 0 0 0 60px;

      @media (max-width: 767px) {
        padding: 0;
        margin: 0 0 17px;
      }
    }
  }
`;
